
import { computed, defineComponent, PropType, ref, Ref } from 'vue';
import RationsTable from './RationsTable.vue';
import TotalBites from './TotalBites.vue';
import { MenuMealDto, Food, NutritionalPlan } from '@/models/NutritionalPlan';
import inputHelper from '@/helpers/Input.helper';
import { useI18n } from 'vue-i18n';
import { useProfileStore } from '@/store/profile.module';

export default defineComponent({
  components: { RationsTable, TotalBites },
  emits: ['on-update-menu-food', 'on-create-alternative'],
  props: {
    userCanEdit: {
      type: Boolean,
      default: false,
    },
    mealMenu: {
      type: Object as () => any,
    },
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlan>,
      required: true,
    },
    showCompletedMealTag: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const storeProfile = useProfileStore();
    const editMode = ref(false);
    const submiting = ref(false);
    const mutableRations: Ref<MenuMealDto> = ref(new MenuMealDto(inputHelper.createCopy(props.mealMenu)));
    const errorInName = computed(() => {
      return (
        mutableRations.value.name !== undefined &&
        mutableRations.value.name !== null &&
        mutableRations.value.name?.length > 46
      );
    });

    const onEditForm = () => {
      editMode.value = true;
    };

    const cleanIncompleteFoods = async () => {
      return mutableRations.value.mealDetails.filter((food) => food.foodId && food.bites && food.bites > 0);
    };

    const onSave = async () => {
      submiting.value = true;
      mutableRations.value.mealDetails = await cleanIncompleteFoods();
      emit('on-update-menu-food', mutableRations.value);
      submiting.value = false;
      editMode.value = false;
    };
    const onCancel = () => {
      editMode.value = false;
      mutableRations.value = new MenuMealDto(inputHelper.createCopy(props.mealMenu));
    };

    const onQuantityChange = (newValue: any, foodIndex: number) => {
      mutableRations.value.mealDetails[foodIndex].bites = newValue.value;
    };

    const onDeleteFood = (foodIndex: number) => {
      mutableRations.value.mealDetails.splice(foodIndex, 1);
    };

    const onNewFoodClick = () => {
      mutableRations.value.mealDetails.push({
        food: {
          id: '',
          name: '',
          quantity: 0,
          calories: 0,
          protein: 0,
          fat: 0,
          carbohydrates: 0,
          proteinBites: 0,
          fatBites: 0,
          carbohydratesBites: 0,
          units: '',
        },
        bites: 1,
        foodId: '',
        accountId: storeProfile.accountId,
      });
    };
    const onFoodChange = (newFood: Food, foodIndex: number) => {
      mutableRations.value.mealDetails[foodIndex].food = newFood;
      mutableRations.value.mealDetails[foodIndex].foodId = newFood.id;
    };

    const onNameChange = (newName: string) => {
      mutableRations.value.name = newName;
    };

    const onCreateAlternative = (mealId: string) => {
      emit('on-create-alternative', mealId, props.mealMenu.name ?? t(`nutrition.${props.mealMenu.mealType}`));
    };

    return {
      editMode,
      mutableRations,
      submiting,
      errorInName,

      onEditForm,
      onSave,
      onCancel,
      onNewFoodClick,
      onQuantityChange,
      onDeleteFood,
      onFoodChange,
      onNameChange,
      onCreateAlternative,
    };
  },
});
