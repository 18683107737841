import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card c-meal-container"
}
const _hoisted_2 = { class: "c-meal-title" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = {
  key: 0,
  class: "c-meal-description-container"
}
const _hoisted_5 = {
  key: 1,
  class: "c-meal-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RationsTable = _resolveComponent("RationsTable")!
  const _component_TotalBites = _resolveComponent("TotalBites")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.mealMenu)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-meals",
              "aria-hidden": "true"
            }, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.$t(`nutrition.${_ctx.mealMenu.mealType}`)), 1)
          ]),
          _withDirectives((_openBlock(), _createBlock(_component_Button, {
            disabled: !_ctx.userCanEdit,
            class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
            onClick: _ctx.onEditForm
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: "icon-edit",
                "aria-label": _ctx.$t('common.edit'),
                "aria-hidden": "true"
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["disabled", "class", "onClick"])), [
            [
              _directive_tooltip,
              _ctx.$t('common.edit'),
              void 0,
              { bottom: true }
            ]
          ])
        ]),
        (_ctx.mutableRations)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_RationsTable, {
                "edit-mode": _ctx.editMode,
                name: _ctx.mutableRations.name,
                foods: _ctx.mutableRations.mealDetails,
                "meal-menu": _ctx.mealMenu,
                "food-intolerances": _ctx.nutritionalPlan.foodIntolerances,
                onOnQuantiyChange: _ctx.onQuantityChange,
                onOnDeleteFood: _ctx.onDeleteFood,
                onOnNewFood: _ctx.onNewFoodClick,
                onOnFoodChange: _ctx.onFoodChange,
                onOnNameChange: _ctx.onNameChange,
                onOnCreateAlternative: _ctx.onCreateAlternative
              }, null, 8, ["edit-mode", "name", "foods", "meal-menu", "food-intolerances", "onOnQuantiyChange", "onOnDeleteFood", "onOnNewFood", "onOnFoodChange", "onOnNameChange", "onOnCreateAlternative"]),
              _createVNode(_component_TotalBites, {
                nutritionalPlan: _ctx.nutritionalPlan,
                "meal-menu": _ctx.mealMenu,
                "show-completed-meal-tag": _ctx.showCompletedMealTag
              }, null, 8, ["nutritionalPlan", "meal-menu", "show-completed-meal-tag"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.editMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Button, {
                label: _ctx.$t('common.cancel'),
                class: "p-button p-component p-button-secondary p-button-medium",
                onClick: _ctx.onCancel
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_Button, {
                loading: _ctx.submiting,
                label: _ctx.$t('common.save'),
                class: "p-button p-component p-button-info p-button-medium",
                disabled: _ctx.errorInName,
                onClick: _ctx.onSave
              }, null, 8, ["loading", "label", "disabled", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}